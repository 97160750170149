<template>
  <div>
    <div class="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div
          :class="['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':current == 1}]
          "
          @click="
            () => {
              next(1);
            }
          "
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-8 w-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

          General Information
</div>  
<div
          :class="['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':current == 2}]
          "
          @click="
            () => {
              next(2);
            }
          "
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="h-8 w-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
</svg>


          Employment Information
</div>
<div
          :class="['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':current == 4}]
          "
          @click="
            () => {
              next(4);
            }
          "
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="h-8 w-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
</svg>



          Document Upload
</div>
<div
          :class="['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':current == 5}]
          "
          @click="
            () => {
              next(5);
            }
          "
        >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-8 w-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>




          Payment Information
</div>
    </div>
    <div class="my-10" v-if="current == 1">
      <!-- <test-form></test-form> -->
      <GeneralInformation :user="user" />
    </div>
    <div class="my-10" v-if="current == 2">
      <EmploymentInformation :user="user" />
    </div>
    <div class="my-10" v-if="current == 3">
      <EducationInformation :user="user"></EducationInformation>
    </div>
    <div class="my-10" v-if="current == 4">
      <CertificateUpload :user="user"></CertificateUpload>
    </div>
    <div class="my-10" v-if="current == 5">
      <SubscriptionPayment :user="user"></SubscriptionPayment>
    </div>
  </div>
</template>
<script>
import GeneralInformation from "./General-Information.vue";
import EmploymentInformation from "./Employment-Information.vue";
import EducationInformation from "./Education-Information.vue";
import CertificateUpload from "./Certificate-Upload.vue";
import SubscriptionPayment from "./Subscription-Payment.vue";
import { auth } from "../../database/index";
import { mapState } from "vuex";
import TestForm from './TestForm.vue';
export default {
  data() {
    return {};
  },
  methods: {
    next(value) {
      this.$store.dispatch("changeStep", value);
    },
    prev() {
      this.current--;
    },
  },
  components: {
    GeneralInformation,
    EmploymentInformation,
    EducationInformation,
    CertificateUpload,
    SubscriptionPayment,
    TestForm,
  },
  computed: {
    ...mapState(["allAdvocates", "current"]),
    user() {
      return this.allAdvocates.filter(
        (i) => i.id == auth.currentUser.uid
      )[0];
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 10px;
}
.icon-card-active{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 200px;
  background-color: #db1c22;
  margin-bottom: 10px;
  color:#ffffff
}
.icon-card:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 200px;
  background-color: #db1c22;
  margin-bottom: 10px;
  color:#ffffff
}
.card-p {
  font-size: 24;
  font-weight: bold;
}
.icon-list {
  font-size: 68px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 640px){
  .icon-list {
  font-size: 28px;
  margin-bottom: 5px;
}
	.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100px;
  margin-bottom: 10px;
}
.icon-card:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100px;
  background-color: #db1c22;
  margin-bottom: 10px;
}
.icon-card-active{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100px;
  background-color: #db1c22;
  margin-bottom: 10px;
  color:#ffffff
}
}
</style>
