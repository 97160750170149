var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }}},[_c('div',[_c('h3',{staticClass:"my-10 text-lg"},[_vm._v("Certificate Upload")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"National ID/Passport Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'national_id',
                {
                  initialValue: _vm.user.national_id,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter national id',
                    } ],
                } ]),expression:"[\n                'national_id',\n                {\n                  initialValue: user.national_id,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter national id',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Admission Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_number',
                {
                  initialValue: _vm.user.practise_number,
                  rules: [
                    {
                      required: true,
                      message: 'enter number',
                    } ],
                } ]),expression:"[\n                'practise_number',\n                {\n                  initialValue: user.practise_number,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'enter number',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"","addon-before":"P105./"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Current Practice Certificate"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_certificate',
                {
                  initialValue: _vm.user.practise_certificate,
                  rules: [{ required: true, message: 'Certificate  is required' }],
                } ]),expression:"[\n                'practise_certificate',\n                {\n                  initialValue: user.practise_certificate,\n                  rules: [{ required: true, message: 'Certificate  is required' }],\n                },\n              ]"}],attrs:{"accept":"application/pdf","multiple":false,"list-type":"picture","before-upload":_vm.handleBeforeUpload,"show-upload-list":false,"custom-request":_vm.uploadPracticeCert}},[_c('div',[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" Click or drag file to this area to upload Certificate ")])])])],1),(_vm.uploadPracticeProgress)?_c('a-progress',{attrs:{"percent":_vm.uploadPracticeProgress}}):_vm._e()],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Admission Certificate"}},[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'residence_evidence',
                {
                  initialValue: _vm.user.residence_evidence,
                  rules: [{ required: true, message: 'Certificate  is required' }],
                } ]),expression:"[\n                'residence_evidence',\n                {\n                  initialValue: user.residence_evidence,\n                  rules: [{ required: true, message: 'Certificate  is required' }],\n                },\n              ]"}],attrs:{"accept":"application/pdf","multiple":false,"list-type":"picture","before-upload":_vm.handleBeforeUpload,"show-upload-list":false,"custom-request":_vm.uploadAdmissionCert}},[_c('div',[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" Click or drag file to this area to upload Certificate ")])])])],1),(_vm.uploadAdmissionProgress)?_c('a-progress',{attrs:{"percent":_vm.uploadAdmissionProgress}}):_vm._e()],1)],1),_c('a-checkbox',{staticClass:"mb-3",attrs:{"checked":_vm.terms},on:{"change":_vm.changeTerms}},[_vm._v(" The inforamtion submitted will only be used for the approval of your account "),_c('a',{attrs:{"href":"//https://firebasestorage.googleapis.com/v0/b/scanpal-f74da.appspot.com/o/Privacy%20Policy%20200223.docx?alt=media&token=bd536806-a1ac-4361-a56a-ddfca9885a8f"}},[_vm._v("View privacy policy")])])],1),_c('div',[_c('a-button',{staticClass:"mx-10",attrs:{"type":"dark"},on:{"click":_vm.handlePrevious}},[_vm._v("Previous Section ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.user.status === 'pending approval'||!_vm.terms},on:{"click":_vm.handleSubmit}},[_vm._v("Save and Continue ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }