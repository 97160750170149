var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-4"},[_c('div',{class:['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':_vm.current == 1}],on:{"click":function () {
              _vm.next(1);
            }}},[_c('svg',{staticClass:"h-8 w-8",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"}})]),_vm._v(" General Information ")]),_c('div',{class:['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':_vm.current == 2}],on:{"click":function () {
              _vm.next(2);
            }}},[_c('svg',{staticClass:"h-8 w-8",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","strokeWidth":"{1.5}","stroke":"currentColor"}},[_c('path',{attrs:{"strokeLinecap":"round","strokeLinejoin":"round","d":"M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"}})]),_vm._v(" Employment Information ")]),_c('div',{class:['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':_vm.current == 4}],on:{"click":function () {
              _vm.next(4);
            }}},[_c('svg',{staticClass:"h-8 w-8",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","strokeWidth":"{1.5}","stroke":"currentColor"}},[_c('path',{attrs:{"strokeLinecap":"round","strokeLinejoin":"round","d":"M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"}})]),_vm._v(" Document Upload ")]),_c('div',{class:['border-solid border-2 border-gray-800 rounded-lg h-12 flex items-center gap-2 p-3 text-gray-500 text-lg ',{'border-red-500 text-red-500':_vm.current == 5}],on:{"click":function () {
              _vm.next(5);
            }}},[_c('svg',{staticClass:"h-8 w-8",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"}})]),_vm._v(" Payment Information ")])]),(_vm.current == 1)?_c('div',{staticClass:"my-10"},[_c('GeneralInformation',{attrs:{"user":_vm.user}})],1):_vm._e(),(_vm.current == 2)?_c('div',{staticClass:"my-10"},[_c('EmploymentInformation',{attrs:{"user":_vm.user}})],1):_vm._e(),(_vm.current == 3)?_c('div',{staticClass:"my-10"},[_c('EducationInformation',{attrs:{"user":_vm.user}})],1):_vm._e(),(_vm.current == 4)?_c('div',{staticClass:"my-10"},[_c('CertificateUpload',{attrs:{"user":_vm.user}})],1):_vm._e(),(_vm.current == 5)?_c('div',{staticClass:"my-10"},[_c('SubscriptionPayment',{attrs:{"user":_vm.user}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }