<template>
  <div>
    <a-row type="flex" :gutter="24">
      <a-col :span="24">
        <profile-information
          v-if="
            user.status != 'admin' &&   
            user.status != 'firm' &&
            user.status != 'firm active'
          "
        ></profile-information>
        <FirmProfileForm
          v-if="user.status == 'firm' || user.status == 'firm active'"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ProfileInformation from "../components/forms/ProfileInformation.vue";
import { mapState } from "vuex";
import * as fb from "../firebase";
import FirmProfileForm from "../components/firm/FirmProfileForm.vue";

export default {
  components: {
    ProfileInformation,
    FirmProfileForm,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["allAdvocates"]),
    user() {
      return this.allAdvocates.filter(
        (i) => i.id == fb.auth.currentUser.uid
      )[0];
    },
  },
};
</script>

<style lang="scss">
</style>