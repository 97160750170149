var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }}},[_c('div',[_c('h3',{staticClass:"text-lg mb-5"},[_vm._v("General Information")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'first_name',

                {
                  initialValue: _vm.user.first_name,

                  rules: [
                    { required: true, message: 'Please enter user name' } ],
                } ]),expression:"[\n                'first_name',\n\n                {\n                  initialValue: user.first_name,\n\n                  rules: [\n                    { required: true, message: 'Please enter user name' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please enter your first name"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'last_name',
                {
                  initialValue: _vm.user.last_name,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your last name',
                    } ],
                } ]),expression:"[\n                'last_name',\n                {\n                  initialValue: user.last_name,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your last name',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"last name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  initialValue: _vm.user.email,
                  rules: [
                    { required: true, message: 'Please select your email' } ],
                } ]),expression:"[\n                'email',\n                {\n                  initialValue: user.email,\n                  rules: [\n                    { required: true, message: 'Please select your email' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please a valid email","type":"email"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone',
                {
                  initialValue: _vm.user.phone,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    } ],
                } ]),expression:"[\n                'phone',\n                {\n                  initialValue: user.phone,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your phone number',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please enter a valid phone number","type":"email"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Primary practice Location(County)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
                {
                  initialValue: _vm.user.location,
                  rules: [
                    { required: true, message: 'Please enter your location' } ],
                } ]),expression:"[\n                'location',\n                {\n                  initialValue: user.location,\n                  rules: [\n                    { required: true, message: 'Please enter your location' },\n                  ],\n                },\n              ]"}]},_vm._l((_vm.counties),function(county){return _c('a-select-option',{key:county,attrs:{"value":county}},[_vm._v(" "+_vm._s(county)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Other Counties of practice(Optional)"}},[_c('a-tooltip',{attrs:{"slot":"suffix","title":"Enter Freelance if currently unemployed"},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"info-circle"}})],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'other_counties',
                {
                  initialValue: _vm.user.other_counties,
                  rules: [{ required: false, message: 'field is required' }],
                } ]),expression:"[\n                'other_counties',\n                {\n                  initialValue: user.other_counties,\n                  rules: [{ required: false, message: 'field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","placeholder":"Type or search"}},_vm._l((_vm.counties),function(i){return _c('a-select-option',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Website(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'website',
                {
                  initialValue: _vm.user.website,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter your job title',
                    } ],
                } ]),expression:"[\n                'website',\n                {\n                  initialValue: user.website,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please enter your job title',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Job Title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'job_title',
                {
                  initialValue: _vm.user.job_title,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your job title',
                    } ],
                } ]),expression:"[\n                'job_title',\n                {\n                  initialValue: user.job_title,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your job title',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Primary Practice Area"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'specialisation',
                {
                  initialValue: _vm.user.specialisation,
                  rules: [{ required: true, message: 'field is required' }],
                } ]),expression:"[\n                'specialisation',\n                {\n                  initialValue: user.specialisation,\n                  rules: [{ required: true, message: 'field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Type or search"}},_vm._l((_vm.practiseAreas),function(i){return _c('a-select-option',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Other Practice Areas (Select upto 3 that apply)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_areas',
                {
                  initialValue: _vm.user.practise_areas,
                  rules: [{ required: true, message: 'field is required' }],
                } ]),expression:"[\n                'practise_areas',\n                {\n                  initialValue: user.practise_areas,\n                  rules: [{ required: true, message: 'field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"tags","maxTagCount":3,"placeholder":"Type or search"}},_vm._l((_vm.practiseAreas),function(i){return _c('a-select-option',{key:i},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Your LinkedIn link(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'linkedIn',
                {
                  initialValue: _vm.user.linkedIn,
                  rules: [
                    {
                      required: false,
                      message: 'Filed is required',
                    } ],
                } ]),expression:"[\n                'linkedIn',\n                {\n                  initialValue: user.linkedIn,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Filed is required',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Your Twitter Link(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'twitter',
                {
                  initialValue: _vm.user.twitter,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter your job title',
                    } ],
                } ]),expression:"[\n                'twitter',\n                {\n                  initialValue: user.twitter,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please enter your job title',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Short Biography"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'biography',
                {
                  initialValue: _vm.user.biography,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your biography',
                    } ],
                } ]),expression:"[\n                'biography',\n                {\n                  initialValue: user.biography,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your biography',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"rows":4,"placeholder":"Enter a short biography"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Date of Admission to the Bar."}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_start',
                {
                  initialValue: _vm.user.practise_start,
                  rules: [
                    {
                      required: true,
                      message:
                        'Please enter Year of Admission to the Nigerian Bar.',
                    } ],
                } ]),expression:"[\n                'practise_start',\n                {\n                  initialValue: user.practise_start,\n                  rules: [\n                    {\n                      required: true,\n                      message:\n                        'Please enter Year of Admission to the Nigerian Bar.',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled-date":_vm.disabledDate,"placeholder":"Select date"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Profile Picture"}},[_c('input',{staticClass:"block w-50 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400",attrs:{"id":"multiple_files","type":"file","accept":"image/*"},on:{"change":_vm.loadImage}})]),_c('a-progress',{attrs:{"percent":_vm.uploadProgress}})],1),_c('a-col',{attrs:{"span":24,"md":12}},[(_vm.user.profile_photo)?_c('a-avatar',{attrs:{"src":_vm.user.profile_photo,"size":160}}):_c('a-avatar',{attrs:{"icon":"user","size":160}})],1)],1),_c('a-checkbox',{staticClass:"mb-3",attrs:{"checked":_vm.terms},on:{"change":_vm.changeTerms}},[_vm._v(" I understand that the information submitted on this page will be visibile to all potential clients ")])],1),_c('div',[_c('a-modal',{attrs:{"title":"Resize Image to Fit","on-ok":"handleOk"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" Close ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.uploadToFirestore}},[_vm._v(" Upload ")])],1),_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image.src,"stencil-props":{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1,
          },"resize-image":{
            adjustStencil: false,
          },"image-restriction":"stencil","stencil-size":{
            width: 144,
            height: 144,
          }}})],2)],1),_c('div',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.user.status === 'pending approval' || !_vm.terms},on:{"click":_vm.handleSubmit}},[_vm._v("Save and Continue ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }