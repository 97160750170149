var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }}},[_c('div',[_c('h3',[_vm._v("Employment Information")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('h5',{staticClass:"my-5"},[_vm._v("Your Current Employer")]),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":18}},[_c('a-form-item',{attrs:{"label":"Company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'current_employer',
                { initialValue: _vm.user.current_employer,
                  rules: [
                    { required: true, message: 'Field is required' } ],
                } ]),expression:"[\n                'current_employer',\n                { initialValue: user.current_employer,\n                  rules: [\n                    { required: true, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"current employer","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"Starting Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'current_starting',
                { initialValue: _vm.user.current_starting,
                  rules: [
                    {
                      required: true,
                      message: 'Field is required',
                    } ],
                } ]),expression:"[\n                'current_starting',\n                { initialValue: user.current_starting,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Field is required',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select date","disabled":""}})],1)],1)],1),_c('h5',{staticClass:"my-10"},[_vm._v("Your Previous Employer")]),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":14}},[_c('a-form-item',{attrs:{"label":"Company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev1',
                { initialValue: _vm.user.prev1,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev1',\n                { initialValue: user.prev1,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"employer","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Starting Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev1starting',
                { initialValue: _vm.user.prev1starting,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev1starting',\n                { initialValue: user.prev1starting,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Start","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Ending Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev1ending',
                { initialValue: _vm.user.prev1ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev1ending',\n                { initialValue: user.prev1ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"End","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":14}},[_c('a-form-item',{attrs:{"label":"Company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2',
                { initialValue: _vm.user.prev2,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev2',\n                { initialValue: user.prev2,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"employer","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Starting Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2starting',
                { initialValue: _vm.user.prev2starting,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev2starting',\n                { initialValue: user.prev2starting,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Start","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Ending Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2ending',
                { initialValue: _vm.user.prev2ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev2ending',\n                { initialValue: user.prev2ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"End","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":14}},[_c('a-form-item',{attrs:{"label":"Company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3',
                { initialValue: _vm.user.prev3,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev3',\n                { initialValue: user.prev3,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"employer","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Starting Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3starting',
                { initialValue: _vm.user.prev3starting,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev3starting',\n                { initialValue: user.prev3starting,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Start","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Ending Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3ending',
                { initialValue: _vm.user.prev3ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev3ending',\n                { initialValue: user.prev3ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"End","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":14}},[_c('a-form-item',{attrs:{"label":"Company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4',
                { initialValue: _vm.user.prev4,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev4',\n                { initialValue: user.prev4,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"employer","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Starting Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4starting',
                { initialValue: _vm.user.prev4starting,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev4starting',\n                { initialValue: user.prev4starting,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Start","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Ending Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4ending',
                { initialValue: _vm.user.prev4ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev4ending',\n                { initialValue: user.prev4ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"End","disabled":""}})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }