var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }}},[_c('div',[_c('h3',[_vm._v("General Information")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'first_name',

                {
                  initialValue: _vm.user.first_name,

                  rules: [
                    { required: true, message: 'Please enter user name' } ],
                } ]),expression:"[\n                'first_name',\n\n                {\n                  initialValue: user.first_name,\n\n                  rules: [\n                    { required: true, message: 'Please enter user name' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please enter your first name","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'last_name',
                {
                  initialValue: _vm.user.last_name,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your last name',
                    } ],
                } ]),expression:"[\n                'last_name',\n                {\n                  initialValue: user.last_name,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your last name',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"last name","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  initialValue: _vm.user.email,
                  rules: [
                    { required: true, message: 'Please select your email' } ],
                } ]),expression:"[\n                'email',\n                {\n                  initialValue: user.email,\n                  rules: [\n                    { required: true, message: 'Please select your email' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please a valid email","type":"email","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone',
                {
                  initialValue: _vm.user.phone,
                  rules: [
                    {
                      required: true,
                      message: 'Please select your phone number',
                    } ],
                } ]),expression:"[\n                'phone',\n                {\n                  initialValue: user.phone,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select your phone number',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please a valid phone number","type":"email","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Location"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
                {
                  initialValue: _vm.user.location,
                  rules: [
                    { required: true, message: 'Please enter your location' } ],
                } ]),expression:"[\n                'location',\n                {\n                  initialValue: user.location,\n                  rules: [\n                    { required: true, message: 'Please enter your location' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Job Ttile"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'job_title',
                {
                  initialValue: _vm.user.job_title,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your job title',
                    } ],
                } ]),expression:"[\n                'job_title',\n                {\n                  initialValue: user.job_title,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your job title',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Practice Areas"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'specialisation',
                { initialValue: _vm.user.specialisation,
                  rules: [
                    { required: true, message: 'field is required' } ],
                } ]),expression:"[\n                'specialisation',\n                { initialValue: user.specialisation,\n                  rules: [\n                    { required: true, message: 'field is required' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"tags","placeholder":"Type or search","disabled":""}},_vm._l((25),function(i){return _c('a-select-option',{key:(i + 9).toString(36) + i},[_vm._v(" "+_vm._s((i + 9).toString(36) + i)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Website"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'website',
                {
                  initialValue: _vm.user.website,
                  rules: [
                    {
                      required: false,
                      message: 'Please enter your job title',
                    } ],
                } ]),expression:"[\n                'website',\n                {\n                  initialValue: user.website,\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please enter your job title',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Short Biography"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'biography',
                {
                  initialValue: _vm.user.biography,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your biography',
                    } ],
                } ]),expression:"[\n                'biography',\n                {\n                  initialValue: user.biography,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter your biography',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"rows":4,"placeholder":"Enter a short biography","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Profile Picture"}},[_c('img',{staticStyle:{"height":"120px"},attrs:{"src":_vm.user.profile_photo,"alt":""}})])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }