<template>
  <a-card>
    <card-advocates></card-advocates>
  </a-card>
</template>

<script>
import CardAdvocates from '../components/Cards/CardAdvocates.vue'
export default {
  components: {CardAdvocates},

}
</script>

<style>

</style>