var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }}},[_c('div',[_c('h3',[_vm._v("Certificates")]),_c('a-form',{staticClass:"mb-5",attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Practise Start Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_start',
                {
                  initialValue: _vm.user.practise_start,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter practice Start Date',
                    } ],
                } ]),expression:"[\n                'practise_start',\n                {\n                  initialValue: user.practise_start,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter practice Start Date',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Select date","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Last Certificate Renewal Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cert_renewal_date',
                {
                  initialValue: _vm.user.cert_renewal_date,
                  rules: [
                    {
                      required: true,
                      message: 'select date',
                    } ],
                } ]),expression:"[\n                'cert_renewal_date',\n                {\n                  initialValue: user.cert_renewal_date,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'select date',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"select date","disabled":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"National ID/Passport"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'national_id',
                {
                  initialValue: _vm.user.national_id,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter national id',
                    } ],
                } ]),expression:"[\n                'national_id',\n                {\n                  initialValue: user.national_id,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter national id',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Select date","disabled":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Practising Certificate Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'practise_number',
                {
                  initialValue: _vm.user.practise_number,
                  rules: [
                    {
                      required: true,
                      message: 'enter number',
                    } ],
                } ]),expression:"[\n                'practise_number',\n                {\n                  initialValue: user.practise_number,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'enter number',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"","disabled":""}})],1)],1)],1),_c('a-row',{staticClass:"mb-5",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-button',{attrs:{"icon":"download","href":_vm.user.practise_certificate,"target":"blank"}},[_vm._v("view Current Practice Certificate")])],1),_c('a-col',{attrs:{"span":6}},[_c('a-button',{attrs:{"icon":"download","href":_vm.user.residence_evidence,"target":"blank"}},[_vm._v("View Admission Certificate")])],1)],1)],1),_c('div',{staticStyle:{"margin-top":"40px"}},[_c('a-button',{staticClass:"mx-10",attrs:{"loading":_vm.loading},on:{"click":function () {
            _vm.handleSubmit('declined');
          }}},[_vm._v("Decline Request ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function () {
            _vm.handleSubmit('approved');
          }}},[_vm._v("Approve Request ")])],1)],1),_c('a-modal',{attrs:{"title":"Add Comments","on-ok":"handleOk"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onClose}},[_vm._v(" Close ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleChange}},[_vm._v(" Submit ")])],1),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",attrs:{"type":"text","id":"comment","placeholder":"add comment"},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}})])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }