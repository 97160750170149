import { render, staticRenderFns } from "./General-Information.vue?vue&type=template&id=6689d244&scoped=true"
import script from "./General-Information.vue?vue&type=script&lang=js"
export * from "./General-Information.vue?vue&type=script&lang=js"
import style0 from "./General-Information.vue?vue&type=style&index=0&id=6689d244&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6689d244",
  null
  
)

export default component.exports