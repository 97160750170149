var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }}},[_c('div',[_c('h3',{staticClass:"text-lg my-10"},[_vm._v("Employment Information")]),_c('p',[_vm._v(" Please provide us with the information of your current and past employemnt ")]),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('h5',{staticClass:"my-5"},[_vm._v("Your Current work place")]),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'current_employer',
                {
                  initialValue: _vm.user.current_employer,
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'current_employer',\n                {\n                  initialValue: user.current_employer,\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":"current company"}},[_c('a-tooltip',{attrs:{"slot":"suffix","title":"Enter Freelance if currently unemployed.you can also use your own firm"},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"info-circle"}})],1)],1)],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Position"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'current_position',
                {
                  initialValue: _vm.user.current_position,
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'current_position',\n                {\n                  initialValue: user.current_position,\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Starting Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'current_starting',
                {
                  initialValue: _vm.user.current_starting,
                  rules: [
                    {
                      required: true,
                      message: 'Field is required',
                    } ],
                } ]),expression:"[\n                'current_starting',\n                {\n                  initialValue: user.current_starting,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Field is required',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled-date":_vm.disabledDate,"placeholder":"Select date"}})],1)],1)],1),_c('h5',{staticClass:"my-10"},[_vm._v("Your Previous Work place (optional)")]),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Company(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2',
                {
                  initialValue: _vm.user.prev2,
                  rules: [{ required: false, message: 'Field is required' }],
                } ]),expression:"[\n                'prev2',\n                {\n                  initialValue: user.prev2,\n                  rules: [{ required: false, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":"company"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Position(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2_position',
                {
                  initialValue: _vm.user.prev2_position,
                  rules: [{ required: false, message: 'Field is required' }],
                } ]),expression:"[\n                'prev2_position',\n                {\n                  initialValue: user.prev2_position,\n                  rules: [{ required: false, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Dates(Optional)"}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2starting',
                { initialValue: _vm.user.prev2starting,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev2starting',\n                { initialValue: user.prev2starting,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],staticClass:"mx-2",attrs:{"disabled-date":_vm.disabledStartDate,"format":"YYYY-MM","placeholder":"Start Date"},on:{"change":_vm.setStartDate,"openChange":_vm.handleStartOpenChange2}}),_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev2ending',
                { initialValue: _vm.user.prev2ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev2ending',\n                { initialValue: user.prev2ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"disabled-date":_vm.disabledEndDate,"placeholder":"End Date","open":_vm.endOpen2},on:{"openChange":_vm.handleEndOpenChange2}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Company(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3',
                {
                  initialValue: _vm.user.prev3,
                  rules: [{ required: false, message: 'Field is required' }],
                } ]),expression:"[\n                'prev3',\n                {\n                  initialValue: user.prev3,\n                  rules: [{ required: false, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":"company"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Position(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3_position',
                {
                  initialValue: _vm.user.prev3_position,
                  rules: [{ required: false, message: 'Field is required' }],
                } ]),expression:"[\n                'prev3_position',\n                {\n                  initialValue: user.prev3_position,\n                  rules: [{ required: false, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Dates(Optional)"}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3starting',
                { initialValue: _vm.user.prev3starting,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev3starting',\n                { initialValue: user.prev3starting,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],staticClass:"mx-2",attrs:{"disabled-date":_vm.disabledStartDate,"format":"YYYY-MM","placeholder":"Start Date"},on:{"change":_vm.setStartDate,"openChange":_vm.handleStartOpenChange3}}),_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev3ending',
                { initialValue: _vm.user.prev3ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev3ending',\n                { initialValue: user.prev3ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"disabled-date":_vm.disabledEndDate,"placeholder":"End Date","open":_vm.endOpen3},on:{"openChange":_vm.handleEndOpenChange3}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Company(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4',
                {
                  initialValue: _vm.user.prev4,
                  rules: [{ required: false, message: 'Field is required' }],
                } ]),expression:"[\n                'prev4',\n                {\n                  initialValue: user.prev4,\n                  rules: [{ required: false, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":"company"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Position(Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4_position',
                {
                  initialValue: _vm.user.prev4_position,
                  rules: [{ required: false, message: 'Field is required' }],
                } ]),expression:"[\n                'prev4_position',\n                {\n                  initialValue: user.prev4_position,\n                  rules: [{ required: false, message: 'Field is required' }],\n                },\n              ]"}],attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Dates(Optional)"}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4starting',
                { initialValue: _vm.user.prev4starting,
                  rules: [
                    { required: false, message: 'Field is required'} ],
                } ]),expression:"[\n                'prev4starting',\n                { initialValue: user.prev4starting,\n                  rules: [\n                    { required: false, message: 'Field is required'},\n                  ],\n                },\n              ]"}],staticClass:"mx-2",attrs:{"disabled-date":_vm.disabledStartDate,"format":"YYYY-MM","placeholder":"Start Date"},on:{"change":_vm.setStartDate,"openChange":_vm.handleStartOpenChange4}}),_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'prev4ending',
                { initialValue: _vm.user.prev4ending,
                  rules: [
                    { required: false, message: 'Field is required' } ],
                } ]),expression:"[\n                'prev4ending',\n                { initialValue: user.prev4ending,\n                  rules: [\n                    { required: false, message: 'Field is required' },\n                  ],\n                },\n              ]"}],attrs:{"disabled-date":_vm.disabledEndDate,"placeholder":"End Date","open":_vm.endOpen4},on:{"openChange":_vm.handleEndOpenChange4}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex"}},[_c('a-button',{staticClass:"mx-10",attrs:{"type":"dark"},on:{"click":_vm.handlePrevious}},[_vm._v(" Previous ")]),(_vm.user.status === 'pending approval')?_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":""},on:{"click":_vm.handleSubmit}},[_vm._v("Save and Continue ")]):_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("Save and Continue ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }